/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2023 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2023 - 2024. Todos los derechos reservados.
 */

/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         6/07/23 - 1:05 AM
 * Module name:  fa-spin.directives
 * File name:    fa-spin.directives.ts
 * IDE:          WebStorm
 */

import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {ANIMATION_CONFIG} from '../../constants';
import {SpinAnimationType} from '../../types/spin-animation.type';

@Directive({
  selector: 'nb-icon[mkadFaSpin]'
})
export class FaSpinDirective {
  @Input() animaExtra: SpinAnimationType = '';
  @Input() animaDuration = 5;
  protected animationProperty: string[] = [];
  protected animationValue: string[] = [];
  readonly none: string = 'none';

  /**
   * Constructor class
   * @param myself
   */
  constructor(private myself: ElementRef) {
  }

  /**
   * click listener
   * @HostListener click event name
   */
  @HostListener('click')
  onClick(): void {
    this.setAnimation(this.animaExtra);
  }

  private setAnimation(animation: SpinAnimationType): void {
    this.setAnimationValue(animation !== null);
    setTimeout((): void => {
      this.setAnimationValue(false);
    }, this.animaDuration * 100);
  }

  private setAnimationValue(valid: boolean): void {
    this.animationProperty = valid ? ANIMATION_CONFIG.property.optional.concat(ANIMATION_CONFIG.property.strict) : ANIMATION_CONFIG.property.optional;
    this.animationValue = valid ? ANIMATION_CONFIG.value : [this.none, this.none];
    this.animationProperty.forEach((property: string, index: number): void => {
      this.myself.nativeElement.style[property] = this.animationValue[index];
    });
  }

}
