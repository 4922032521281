import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {NgClass, NgForOf, NgStyle} from '@angular/common';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbToggleModule,
  NbTooltipModule,
} from '@nebular/theme';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ZbUserProfileInterface} from '@zibanu/auth';
import {HttpResponse} from '@angular/common/http';
import {
  DialogResponseInterface,
  MkadTargetEventInterface,
  MkadResumeDataListInterface,
  UserProfileInterface,
  UserProfileRequestInterface,
} from '@shared/interface';
import {MkadValidators, ParseFileToBase64} from '@shared/utils';
import {fileErrors, MESSAGE_TIMEOUT_OPTION, THEME_OPTION} from '@shared/constants';
import {MkadBaseComponent, MkadFormLabelComponent, MkadWrapperDialogFormComponent} from '@shared/components';

@Component({
  selector: 'mkad-user-profile',
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    NgStyle,
    ReactiveFormsModule,
    NgForOf,
    NbCardModule,
    NbActionsModule,
    NbSpinnerModule,
    NbIconModule,
    NbTabsetModule,
    NbFormFieldModule,
    NbInputModule,
    NbTooltipModule,
    NbToggleModule,
    NbButtonModule,
    NbSelectModule,
    MkadWrapperDialogFormComponent,
    MkadFormLabelComponent,
  ],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [MkadValidators]
})
export class UserProfileComponent extends MkadBaseComponent<UserProfileComponent> implements OnInit, OnDestroy {
  private readonly validators = inject(MkadValidators);
  private readonly builder = inject(FormBuilder);
  override toTranslate = {
    'app.shared.userProfile.message.success': '',
    'app.shared.userProfile.field.avatar.message.errorSize': '',
    'app.shared.userProfile.field.avatar.message.errorProportion': '',
    'app.shared.userProfile.field.avatar.message.errorInvalid': '',
    'app.shared.userProfile.field.avatar.message.error': '',
  };
  userForm!: FormGroup;
  userData?: UserProfileInterface;
  messageTimeOut: number[] = MESSAGE_TIMEOUT_OPTION;
  languageList: MkadResumeDataListInterface<string>[] = [];
  timeZoneList: string[] = [];
  themeList: MkadResumeDataListInterface<string>[] = THEME_OPTION;
  _avatarSelected?: string;

  get avatar(): string | undefined {
    return this._avatarSelected;
  }

  set avatar(value: string | undefined) {
    this._avatarSelected = value;
  }

  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   * @param dialogRef NbDialogRef dependency injection
   */
  constructor(
    override translate: TranslateService,
    override dialogRef: NbDialogRef<UserProfileComponent>) {
    super(translate, dialogRef);
  }

  /**
   * @description
   * Method that response to OnInit
   */
  override ngOnInit() {
    super.ngOnInit();
    this.getLanguageList();
    this.getTimeZoneList();
    this.getUserdata();
    this.translateThemeList();
  }

  /**
   * @description
   * Method implemented by the OnDestroy lifecycle
   * @override
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * @description
   * Method that translates theme list
   * @override
   */
  private translateThemeList() {
    const themeTranslate = structuredClone([...THEME_OPTION]);
    this.themeList = this.getTranslateMap(themeTranslate, ['name']);
  }

  /**
   * @description
   * Method used to get user data
   */
  getUserdata(): void {
    const {
      first_name,
      last_name,
      email,
      timezone,
      theme,
      lang,
      avatar,
      messages_timeout,
      keep_logged_in,
      app_settings,
      multiple_login,
      secure_password,
    } = this.preferences.userSettings;
    this.userData = {
      first_name,
      last_name,
      email,
      profile: {
        timezone, theme, lang, avatar, messages_timeout, keep_logged_in, app_settings, multiple_login, secure_password,
      },
    };
    this.buildFrom();
  }

  /**
   * @description
   * Method that obtains the list of language
   */
  getLanguageList() {
    this.preferences.getLanguageData().subscribe({
      next: (response) => {
        if (response) {
          this.languageList = [...response]
        }
      },
      error: (error) => this.handleErrorResponse(error),
    });
  }

  /**
   * @description
   * Method that obtains the list of time zones
   */
  getTimeZoneList() {
    this.preferences.getTimeZoneData().subscribe({
      next: (response) => {
        if (response) this.timeZoneList = [...response];
      },
      error: (error) => this.handleErrorResponse(error),
    });
  }

  /**
   * @description
   * Method that initializes the form
   */
  private buildFrom() {
    if (!this.userData) return this.isLoading = false;
    if (!this.userData.profile) return this.isLoading = false;
    this.userForm = this.builder.group({
      avatar: [this.userData.profile.avatar],
      name: [this.userData.first_name, this.validators.userProfile.name()],
      lastname: [this.userData.last_name, this.validators.userProfile.lastname()],
      email: [{disabled: true, value: this.userData.email}],
      multipleSession: [this.userData.profile.multiple_login],
      keepLogin: [this.userData.profile.keep_logged_in],
      securePassword: [this.userData.profile.secure_password],
      timeZone: [this.userData.profile.timezone],
      language: [this.userData.profile.lang],
      theme: [this.userData.profile.theme],
      message: [this.userData.profile.messages_timeout],
    });
    this.avatar = this.userData.profile.avatar;
    return this.isLoading = false;
  }

  /**
   * @description
   * Method that makes the request to update a profile user
   * @param {UserProfileRequestInterface} profile
   * @private
   */
  private updateProfileUser(profile: UserProfileRequestInterface): void {
    this.isLoading = true;
    this.preferences.updateProfile(profile).subscribe({
      next: (response: HttpResponse<ZbUserProfileInterface>) => {
        if (response === null) {
          this.preferences.userSettings = {...profile};
          this.translate.use(profile.lang ?? this.translate.currentLang).subscribe({
            next: () => {
              this.showSuccess(this.translate.instant('app.shared.userProfile.message.success'));
              this.onClickClose({save: true});
            },
            error: (response) => this.handleErrorResponse(response),
          });
        }
        this.isLoading = false;
      },
      error: (errorResponse) => this.handleErrorResponse(errorResponse),
    });
  }

  /**
   * @description
   * Method that responds to submit button click event
   */
  onClickSubmit(): void {
    this.isLoading = true;
    const requestData: Omit<UserProfileRequestInterface, 'app_settings'> = {
      first_name: this.userForm.controls['name'].value,
      last_name: this.userForm.controls['lastname'].value,
      timezone: this.userForm.controls['timeZone'].value,
      theme: this.userForm.controls['theme'].value,
      messages_timeout: this.userForm.controls['message'].value,
      keep_logged_in: this.userForm.controls['keepLogin'].value,
      lang: this.userForm.controls['language'].value,
      multiple_login: this.userForm.controls['multipleSession'].value,
      secure_password: this.userForm.controls['securePassword'].value,
    };
    if (this.userData && this.userData.profile && this.avatar !== this.userData.profile.avatar) {
      requestData.avatar = this.avatar;
    }
    this.updateProfileUser(<UserProfileRequestInterface>requestData);
  }

  /**
   * @description
   * Method that closes the modal
   */
  onClickClose(data: DialogResponseInterface<null> | null): void {
    this.dialogRef.close(data);
  }

  /**
   * @description
   * Method that returns if the form validations are correct.
   */
  isFormValid(): boolean {
    return this.userForm?.valid;
  }

  /**
   * @description
   * Method that returns if a specific field has errors.
   * @param control field form control
   * @private
   */
  private validStatusField(control: string) {
    return this.hasErrorField(control) ? 'danger' : 'success';
  }

  /**
   * @description
   * Method that returns if a specific field has errors.
   * @param control field form control
   * @private
   */
  private hasErrorField(control: string): boolean {
    return !!this.userForm?.controls[control].errors;
  }

  /**
   * @description
   * Method that returns if a specific field has TOUCHED.
   * @param control field form control
   * @private
   */
  private hasTouchedField(control: string): boolean {
    return this.userForm?.controls[control].dirty || this.userForm?.controls[control].touched;
  }


  /**
   * @description
   * Method that returns the status of the input
   * @param control
   */
  statusField(control: string) {
    return this.validStatusField(control);
  }

  /**
   * @description
   * Method that changes the image file.
   * @param event
   */
  onChangeFile(event: Event) {
    this.isLoading = true;
    const {files} = <MkadTargetEventInterface>event.target;
    if (!files) return;
    ParseFileToBase64(files[0]).then(base => {
      this.avatar = base?.toString() ?? '';
    }).catch((error) => {
      if (error == fileErrors.maxSize) return this.showError(this.getTranslate('app.shared.userProfile.field.avatar.message.errorSize'));
      if (error == fileErrors.proportion) return this.showError(this.getTranslate('app.shared.userProfile.field.avatar.message.errorProportion'));
      if (error == fileErrors.format) return this.showError(this.getTranslate('app.shared.userProfile.field.avatar.message.errorInvalid'));
      this.showError(this.getTranslate('app.shared.userProfile.field.avatar.message.error'));
    }).finally(() => this.isLoading = false);
  }
}
